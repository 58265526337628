<template>
  <div>
    <Modal
      v-model="createModal"
      width="70%"
      footer-hide
    >
      <p
        slot="header"
        class="text-center"
      >创建施工申请表</p>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="3">
          <DatePicker
            type="daterange"
            placeholder="选择起止日期"
            size="small"
            transfer
            style="width: 100%"
            @on-change="onChangeDates"
          ></DatePicker>
        </i-col>
        <!-- <i-col span="4">
          <i-input
            v-model.trim="query.keyword"
            size="small"
            placeholder="关键字：编号"
          ></i-input>
        </i-col> -->
        <i-col span="4">
          <Button
            type="primary"
            icon="ios-search"
            size="small"
            class="m-r-5"
            @click="onSearch"
          >搜索</Button>
        </i-col>
      </Row>
      <div class="m-b-10">
        <Button
          type="success"
          size="small"
          :disabled="btnCreate"
          :loading="btnCreateLoading"
          @click="onConfirmCreate"
        >确认创建</Button>
      </div>
      <div>
        <Table
          stripe
          max-height="620"
          size="small"
          :data="tableList"
          :columns="tableColumns"
          :loading="tableLoading"
          @on-selection-change="selectionChange"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            :current="query.pageNumber"
            @on-change="onChangePageNum"
          ></Page>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { getWorkdayPage } from '@/api/msp/workdayTable'
import { createWorkdayManage } from '@/api/msp/workdayManage'
export default {
  props: {
    applyType: {
      type: Number,
      required: true,
      default: 1
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  data () {
    return {
      createModal: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        // companyId: undefined,
        isAddManage: false,
        type: null,
        startDate: '',
        endDate: '',
        status: 1,
        keyword: ''
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center' },
        { title: '编号', align: 'center', minWidth: 120, key: 'code' },
        {
          title: '施工日期',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            return h('span', `${row.startDate} 至 ${row.endDate}`)
          }
        },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        { title: '申请人', align: 'center', minWidth: 120, key: 'userName' },
        { title: '创建时间', align: 'center', minWidth: 120, key: 'createTime' },
        { title: '状态', align: 'center', minWidth: 120, key: 'statusName' }
      ],
      selectedItems: [],
      btnCreateLoading: false
    }
  },
  computed: {
    btnCreate () {
      return this.selectedItems.length === 0
    }
  },
  methods: {
    showModal () {
      this.query.type = this.applyType
      this.getTableData()
      this.createModal = true
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onSearch () {
      this.query.pageNumber = 1
      this.getTableData()
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedItems = selections.map(x => x.id)
    },
    onConfirmCreate () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const postData = {
            tableIds: JSON.stringify(this.selectedItems),
            type: this.applyType
          }
          const result = await createWorkdayManage(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.createModal = false
            this.selectedItems = []
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
