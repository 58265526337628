import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 施工计划表中纳入新的作业计划
 * @param {Object} data
 * @returns
 */
export function addWorkdayToTable (data) {
  return request.post('/ooh-msp/v1/workdayTable/addWorkdayToTable', qs.stringify(data))
}

/**
 * 创建施工计划表
 * @param {Object} data
 * @returns
 */
export function createWorkdayTable (data) {
  return request.post('/ooh-msp/v1/workdayTable/createWorkdayTable', qs.stringify(data))
}

/**
 * 根据施工计划表id查询施工计划列表
 * @param {Object} data
 * @returns
 */
export function getTableWorkdayPage (data) {
  return request.post('/ooh-msp/v1/workdayTable/getTableWorkdayPage', qs.stringify(data))
}

/**
 * 施工计划按指定维度汇总
 * @param {Object} data
 * @returns
 */
export function getTaskitemGather (data) {
  return request.post('/ooh-msp/v1/workdayTable/getTaskitemGather', qs.stringify(data))
}

/**
 * 分页查询经营方施工旬计划表
 * @param {Object} data
 * @returns
 */
export function getWorkdayPage (data) {
  return request.post('/ooh-msp/v1/workdayTable/getWorkdayPage', qs.stringify(data))
}

/**
 * 将施工计划移除施工计划表
 * @param {Object} data
 * @returns
 */
export function removeWorkday (data) {
  return request.post('/ooh-msp/v1/workdayTable/removeWorkday', qs.stringify(data))
}

/**
 * 经营方提交施工计划申请
 * @param {Object} data
 * @returns
 */
export function submitWorkday (data) {
  return request.post('/ooh-msp/v1/workdayTable/submitWorkday', qs.stringify(data))
}

/**
 * 根据施工计划旬表id获取施工计划信息
 * @param {Object} data
 * @returns
 */
export function getWorkdayTableInfo (data) {
  return request.post('/ooh-msp/v1/workdayTable/getWorkdayTableInfo', qs.stringify(data))
}
